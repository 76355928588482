import React, { useState } from 'react'

const Search = () => {
  const [q, setQ] = useState("");

 
 
    return (
        <>
       <div className="wrapper">
                    <div className="search-wrapper">
                        <label htmlFor="search-form">
                            <input
                                type="search"
                                name="search"
                                id="search"
                                className="search-input"
                                placeholder="Search for..."
                                value={q}
                                /*
                                // set the value of our useState q
                                //  anytime the user types in the search box
                                */
                                onChange={(e) => setQ(e.target.value)}
                            />   
                            </label>
                            </div>
                            {/* <ul className="card-grid" style={{ marginTop: "10rem"}}>
                        {search(items).map((item) => (
                            <li>
                              
                            <div> {item.email}</div>
                                           
                                    
                            </li>
                        ))}
                    </ul> */}
                            </div>   
        </>
    )
}

export default Search;
