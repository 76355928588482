import axios from 'axios';
import {Auth} from 'aws-amplify';
import {TOKEN_VC_PRESIGNED_API_URL, TOKEN_VC_BUCKET_NAME, BASE_URL, PRESIGNED_API_URL} from "../config"
import { connection, did, vc } from "mui-metablockchain-sdk";
import {netstat} from "../config";
//import {getUserToken} from "./loginService.js"
//https://ssid.metabit.exchange/dev/generatePresignedS3Url
//ssid-token-vc

export const getDID = async () => {
  const did = localStorage.getItem('did');
  console.log(did);
  if(did !== null) {
    return did;
  } else {
    const token = await GetToken();
    let res = await axios.get(`${BASE_URL}getUserDid`, { 'headers':  { 'Authorization': token }});
    if(res.data.message) {
      return res.data.message;
    }
  }
}

export const VCBYSDK = async (did) => {
  const vcArr = [];
  try {
      let api = await connection.buildConnection(netstat);
      const idVC = await vc.getVCIdsByDID(did, api);
      for (const i of idVC) {
        const vcDetails = await vc.getVCs(i, api);
        Object.assign(vcDetails[0], {Status: vcDetails[1], vc_id: i});
        vcArr.push(vcDetails[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      
      return vcArr;
    }
}

export const GetImage = async(path) => {
  let checkUrl = `${PRESIGNED_API_URL}?bucket=${TOKEN_VC_BUCKET_NAME}&path=${path}`;
  const token = await GetToken()

  return await (axios.get
    (`${checkUrl}`, 
    { 'headers':  { 'Authorization': token }})
  .then(res => res.data)
  .catch(err=> err));
}

export const GetToken = async()=>{
    const pairwiseLogin = localStorage.getItem('login')
    if(pairwiseLogin !== null){
        return pairwiseLogin
    }else{
      const token = await Auth.currentSession();
      return token.idToken.jwtToken
    }   
}

// export const verifyStatus = async (obj) => {
//   let provider = await connection.buildConnection(netstat);
//   let did = await getDID();
//   let sdkVCIDs = await VC.getVCIdsByDID('did:yidindji:pasha', provider);
//   console.log(sdkVCIDs);
//   return false;
// }

export const VCList = async ( path, body ) => {
  const token = await GetToken();
  return await (axios.post(`${BASE_URL}${path}`, body ,{ 'headers':  { 'Authorization': token }})
      .then(res => res.data.message)
      .catch(err=> err));
}

export const TokenAdmin = async ( path, body ) => {
    const token = await GetToken();
    let msg;
    await axios.post(`${BASE_URL}${path}`, body ,{ 'headers':  { 'Authorization': token }})
    .then(function(response) {
      if(response.data.message.hasOwnProperty('request_id')) {
        msg = "success"
      }
    })
    .catch(function(error) {
      msg = error.response.data.message;
    })
    return msg;
    
        // .then((res) => res.data.message)
        // .catch(err => err.message));
        
}

/**
 * Fetch the presigned url to which upload should happen.
 * @param {String} idToken Secure Token
 * @param {String} fileName Name of file to be uploaded
 * @returns {Promise} Presigned url api promise.
 */
 export const getS3PresignedData = (idToken, fileName, support, mimetype) => {

    let checkUrl = `${BASE_URL}generatePresignedS3AdminUrl`;

    return new Promise((resolve, reject) => {
      // Get the presigned object
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        params: {
          file_name: fileName,
          content_type: mimetype,
          bucket: TOKEN_VC_BUCKET_NAME,
        },
      };
      if (support) {
        config.params.type = "support";
      }
      axios
        .get(`${checkUrl}`, config)
        .then((res) => resolve({ status: res.status, data: res.data.message }))
        .catch((err) => reject(err));
    });
  };

  /**
 * Upload file to S3 with previously received pre-signed POST data.
 * @param presignedPostData
 * @param file
 * @returns {Promise<any>}
 */
export const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("Content-Type", file.type);
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(presignedPostData.url, formData, config)
        .then((res) => {
          const {
            url,
            fields: { key },
          } = presignedPostData;
          const data = `${url}${key}`;
          // Resolve the file url
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  };
  
  // Object
  // message: "No Token VC Request found."

