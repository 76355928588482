import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import "../styles/Newkyc_style.css";
import Swal from "sweetalert2";
import { Auth } from "aws-amplify";
import axios from "axios";
import useCognitoUser from "../services/useCognitoUser"
import { getS3PresignedData, uploadFileToS3 } from "../services/s3one";

const Govtkyc = (props) => {
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [dob, setDob] = useState("");
  const [residentalAddr, setResidentalAddr] = useState("");
  const [postalAddr, setPostalAddr] = useState("");
  const [citizenshipIDNo, setCitizenshipIDNo] = useState("");
  const [office, setOffice] = useState('');
  const [officeLevel, setOfficeLevel] = useState('');
  const [securityClearanceLevel, setSecurityClearanceLevel] = useState('');
  const [compensationCode, setCompensationCode] = useState('');
  const [proWLevel, setProWLevel] = useState('');
  const [proWAuthNo, setProWAuthNo] = useState('');
  const [dateOfAppointment, setDateOfAppointment] = useState('');
  const [documentName, setDocumentName] = useState("");
  const [documentNo, setDocumentNo] = useState("");
  const [docExpiry, setDocExpiry] = useState("");
  const [frontUrl, setFrontUrl] = useState("");
  const [backUrl, setBackUrl] = useState("");
  const [frontDocName, setFrontDocName] = useState("");
  const [backDocName, setBackDocName] = useState("");
  const [selfieUrl, setSelfieUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [selfieName, setSelfieName] = useState("");
  const {cognitoUser} = useCognitoUser();
  const [kycType,setKycType] = useState(false);

  // useEffect(() => {
  //   setDob(cognitoUser.birthdate);
  //   setEmail(cognitoUser.email);
  // }, [cognitoUser]);

  const emailRegex =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  const nameRegex = new RegExp("^[a-zA-Z][\sa-zA-Z]*"); 

  const handleSubmit = (event) => {
    event.preventDefault();
    const GovtWorkerFormData = {
      kycType: "GovtWorker",
      givenName,
      familyName,
      dob,
      residentalAddr,
      postalAddr,
      citizenshipIDNo,
      office,
      officeLevel,
      securityClearanceLevel,
      compensationCode,
      proWLevel,
      proWAuthNo,
      dateOfAppointment,
      id_document_name: documentName,
      id_document_number: documentNo,
      id_document_expiry: docExpiry,
      document_image_url: fileUrl,
      selfie_image_url: selfieUrl,
      id_document_front: frontUrl,
      id_document_back: backUrl,
    };
    console.log(GovtWorkerFormData, "govt data");

    return Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data);
      if(email) {
        Object.assign(GovtWorkerFormData, {
          // uid: data.idToken.payload.sub,       
           email: email,
        });
      }else {
        Object.assign(GovtWorkerFormData, {
          // uid: data.idToken.payload.sub,       
           phone_number: phone_number,
        });
      }
      axios({
        method: "post",
        url: "https://ssid.metabit.exchange/dev/saveUserKycAdmin",
        data: GovtWorkerFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: data.idToken.jwtToken,
        },
      })
        .then((res) => {
          Swal.fire("Success!", "KYC Submitted Successfully", "success");
        })
        .catch((err) => console.log("Error During submit KYC...", err));
    });
  };

  const btnReset = () => {
    setGivenName("");
    setFamilyName("");
    setEmail("");
    setPhone_number("");
    setDob("");  
    setPostalAddr("");
    setResidentalAddr("");
    setCitizenshipIDNo("");
    setOffice("");
    setOfficeLevel("");
    setCompensationCode("");
    setSecurityClearanceLevel("");
    setProWLevel("");
    setProWAuthNo("");
    setDateOfAppointment("");
    setDocumentName("");
    setDocumentNo('');
    setDocExpiry("");

  };
  // const handleLogout = async () => {
  //   try {
  //     await Auth.signOut();
  //     props.history.push("/login");
  //   } catch (error) {
  //     console.log("error signing out: ", error);
  //   }
  // };

  const selectFrontImage = (e) => {
    setSelectedFile(e.target.files[0]);
    uploadFrontImage(e.target.files[0]);
  };

  const uploadFrontImage = async (selectedFileObj, docType) => {
    console.log("Selected Image Object are as follows.....", selectedFileObj);
    const { name, type } = selectedFileObj;
    const trimFilename = name.substring(0, 5) + "...";
    // if (docType === "document") {
    //   setSelectedFileName(trimFilename);
    // } else {
    //   setSelfie(trimFilename);
    // }
    setFrontDocName(trimFilename);
    console.log("Filename is....", name);
    console.log("File type is....", type);
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      email,
      phone_number
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setFrontUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  console.log("url......", frontUrl);


  const selectBackImage = (e) => {
    setSelectedFile(e.target.files[0]);
    uploadBackImage(e.target.files[0]);
  };

  const uploadBackImage = async (selectedFileObj, docType) => {
    console.log("Selected Image Object are as follows.....", selectedFileObj);
    const { name, type } = selectedFileObj;
    const trimFilename = name.substring(0, 5) + "...";
    // if (docType === "document") {
    //   setSelectedFileName(trimFilename);
    // } else {
    //   setSelfie(trimFilename);
    // }
    setBackDocName(trimFilename);
    console.log("Filename is....", name);
    console.log("File type is....", type);
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      email,
      phone_number
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setBackUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  console.log("url......", backUrl);



  const selectSelfieImage = (e) => {
    setSelectedFile(e.target.files[0]);
    uploadSelfieImage(e.target.files[0]);
  };
  

  const uploadSelfieImage = async (selectedFileObj, docType) => {
    console.log("Selected Image Object are as follows.....", selectedFileObj);
    const { name, type } = selectedFileObj;
    const trimFilename = name.substring(0, 5) + "...";
    // if (docType === "document") {
    //   setSelectedFileName(trimFilename);
    // } else {
    //   setSelfie(trimFilename);
    // }
    setSelfieName(trimFilename);
    // setSelfieName(trimFileName);
    console.log("Filename is....", name);
    console.log("File type is....", type);
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      email,
      phone_number
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setSelfieUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  console.log("url......", fileUrl);

  const selectImage = (e) => {
    setSelectedFile(e.target.files[0]);
    uploadImage(e.target.files[0]);
  };
  const uploadImage = async (selectedFileObj, docType) => {
    console.log("Selected Image Object are as follows.....", selectedFileObj);
    const { name, type } = selectedFileObj;
    const trimFilename = name.substring(0, 5) + "...";
    // if (docType === "document") {
    //   setSelectedFileName(trimFilename);
    // } else {
    //   setSelfie(trimFilename);
    // }
    setSelectedFileName(trimFilename);
    console.log("Filename is....", name);
    console.log("File type is....", type);
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(
      idToken,
      name,
      "ssid-user-kyc-documents",
      false,
      type,
      email,
      phone_number
    );
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setFileUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  console.log("url......", fileUrl);


  const renderNameValidation = () => {
    if(givenName === '') {
        return null;
    }
    return !nameRegex.test(givenName) &&
    (
        <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Name can't start with space  or digits.</h1> 
    ) 
    
}
const renderFamilyNameValidation = () => {
  if(familyName === '') {
      return null;
  }
  return !nameRegex.test(familyName) &&
  (
      <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Name can't start with space or digits.</h1> 
  ) 
  
}
const renderEmailValidation = () => {
  if (email==='') {
    return null;
  }
  return !emailRegex.test(email) && (
    <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}>
      {'Please enter valid Email'}
    </h1>
  )
};
// const renderAddValidation = () => {
//   if(residentalAddr === '') {
//       return null;
//   }
//   return !nameRegex.test(residentalAddr) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Address can't start with space.</h1> 
//   ) 
  
// }
// const renderAdd1Validation = () => {
//   if(postalAddr === '') {
//       return null;
//   }
//   return !nameRegex.test(postalAddr) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Address can't start with space.</h1> 
//   ) 
  
// }
// const renderOfficeValidation = () => {
//   if(office === '') {
//       return null;
//   }
//   return !nameRegex.test(office) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Office can't start with space.</h1> 
//   ) 
  
// }
// const renderOfficeLevelValidation = () => {
//   if(officeLevel === '') {
//       return null;
//   }
//   return !nameRegex.test(officeLevel) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Office Level can't start with space.</h1> 
//   ) 
  
// }
// const renderSecurityClearanceLevel = () => {
//   if(securityClearanceLevel === '') {
//       return null;
//   }
//   return !nameRegex.test(securityClearanceLevel) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" security Clearance Level can't start with space.</h1> 
//   ) 
  
// }
// const renderCompensationCodeValidation = () => {
//   if(compensationCode === '') {
//       return null;
//   }
//   return !nameRegex.test(compensationCode) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Compensation Code can't start with space.</h1> 
//   ) 
  
// }
// const renderProWLevelValidation = () => {
//   if(proWLevel === '') {
//       return null;
//   }
//   return !nameRegex.test(proWLevel) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Pro-W Level can't start with space.</h1> 
//   ) 
  
// }
// const renderProWAuthNoValidation = () => {
//     if(proWAuthNo === '') {
//         return null;
//     }
//     return !nameRegex.test(proWAuthNo) &&
//     (
//         <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" pro-W Auth No can't start with space.</h1> 
//     ) 
    
//   }
//   const renderDateOfAppointment = () => {
//     if(dateOfAppointment === '') {
//         return null;
//     }
//     return !nameRegex.test(dateOfAppointment) &&
//     (
//         <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Pro-W Level can't start with space.</h1> 
//     ) 
    
//   }

// const renderCitizenshipValidation = () => {
//   if(citizenshipIDNo === '') {
//       return null;
//   }
//   return !nameRegex.test(citizenshipIDNo) &&
//   (
//       <h1 className="valid_login_div" style={{color:'red', fontSize: 15, marginTop: 5, marginLeft: 5, marginRight: 5}}> "ERROR" Citizenship ID can't start with space.</h1> 
//   ) 
  
// }

const toggleKycType = () => {
  setKycType(!kycType);
};
  return (
    <>
      <Container className="kyc_container_div" style={{ marginTop: "50px" }}>
        <Row>
          <Col>
            <Heading> <h1> Yidindji Govt. Worker KYC Form</h1>
            <button  onClick={toggleKycType}>{
                                !kycType ?  'Mobile' : 'Email'
                            }</button> </Heading>

            <div className="kycform_div">
              <Form onSubmit={handleSubmit} id="contact-form">
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label className="is_name_div">Name</Form.Label>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="givenName"
                        name="givenName"
                        placeholder="Enter Given Name"
                        value={givenName}
                        onChange={(event) => setGivenName(event.target.value)}
                        required
                      />
                      {renderNameValidation()}
                    </Col>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="familyName"
                        name="familyName"
                        placeholder="Enter Family Name"
                        value={familyName}
                        onChange={(event) => setFamilyName(event.target.value)}
                        required
                      />
                      {renderFamilyNameValidation()}
                    </Col>
                  </Row>
                </Form.Group>
              
                {!kycType ?
                ( <Form.Group className="mb-3">
                                <Row>
                                    <Form.Label className="is_name_div">Email</Form.Label>
                                    <Col md={6}>
                                        <Form.Control
                                            className="input_div"
                                            type="email"
                                            id="email"
                                            name="email"
                                            autoComplete="off"
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            placeholder="Enter Email"
                                            required />
                                            {renderEmailValidation()}
                                    </Col>
                                </Row>
                            </Form.Group> ) : (
                              

<Form.Group className="mb-3">
    <Row>
        <Form.Label className="is_name_div">Mobile Number</Form.Label>
        <Col md={6}>
            <Form.Control
                className="input_div"
                type="text"
                id="mobile"
                name="mobile"
                autoComplete="off"
                value={phone_number}
                onChange={(event) => setPhone_number(event.target.value)}
                placeholder="Enter Mobile Number"
                required />

        </Col>
    </Row>
</Form.Group>
                            )}
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Date of Birth
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="date"
                        id="dob"
                        name="dob"
                        placeholder="Date of Birth"
                        value={dob}
                        onChange={(event) => setDob(event.target.value)}
                        max={new Date().toISOString().split("T")[0]}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label className="is_name_div">Address</Form.Label>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="resAdd"
                        name="resAdd"
                        placeholder="Enter Residential Address"
                        value={residentalAddr}
                        onChange={(event) => setResidentalAddr(event.target.value)}
                        required
                      />
                  
                    </Col>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="posAdd"
                        name="posAdd"
                        placeholder="Enter Postal Address"
                        value={postalAddr}
                        onChange={(event) => setPostalAddr(event.target.value)}
                        required
                      />
                      
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Citizenship ID Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="citizen"
                        name="citizen"
                        placeholder="Citizenship ID Number"
                        value={citizenshipIDNo}
                        onChange={(event) => setCitizenshipIDNo(event.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label className="is_name_div">Office</Form.Label>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="classLicense"
                        name="classLicense"
                        placeholder="Enter office or department"
                        value={office}
                        onChange={(event) => setOffice(event.target.value)}
                        required
                      />
                      
                    </Col>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="license"
                        name="license"
                        placeholder="Enter office level"
                        value={officeLevel}
                        onChange={(event) => setOfficeLevel(event.target.value)}
                        required
                      />
                     
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                     Security Clearance Level
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="passport"
                        name="passport"
                        placeholder="Enter security clearance"
                        value={securityClearanceLevel}
                        onChange={(event) => setSecurityClearanceLevel(event.target.value)}
                        required
                      />
                     
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                    Workers compensation code
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="health"
                        name="health"
                        placeholder="Enter compensation code"
                        value={compensationCode}
                        onChange={(event) => setCompensationCode(event.target.value)}
                        required
                      />
                      
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                    Pro-W level
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="house"
                        name="house"
                        placeholder="Enter pro-W level"
                        value={proWLevel}
                        onChange={(event) => setProWLevel(event.target.value)}
                        required
                      />
                      
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                    Pro-W Authorization Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="citizen"
                        name="citizen"
                        placeholder=" Enter pro-W authorization number"
                        value={proWAuthNo}
                        onChange={(event) => setProWAuthNo(event.target.value)}
                        required
                      />
                    
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                    Date of official appointment
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="date"
                        id="citizen"
                        name="citizen"
                        min={new Date().toISOString().split("T")[0]}
                        value={dateOfAppointment}
                        onChange={(event) => setDateOfAppointment(event.target.value)}
                        required
                      />
                      
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Document Name
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="document name"
                        name="document name"
                        placeholder="Document name"
                        value={documentName}
                        onChange={(event) => setDocumentName(event.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Document Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="document number"
                        name="document number"
                        placeholder="Document Number"
                        value={documentNo}
                        onChange={(event) => setDocumentNo(event.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                    Document expiry Date
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="date"
                        id="doc expiry"
                        name="doc expiry"
                        min={new Date().toISOString().split("T")[0]}
                        value={docExpiry}
                        onChange={(event) => setDocExpiry(event.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Upload Document Front side
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="file"
                        name="file"
                        onChange={selectFrontImage}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Upload Document Back Side
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="file"
                        name="file"
                        onChange={selectBackImage}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Upload Document
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="file"
                        name="file"
                        onChange={selectImage}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Upload Selfie
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="file"
                        name="file"
                        onChange={selectSelfieImage}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <ButtonSubmit className="btn-div" type="submit">
                  Submit
                </ButtonSubmit>
                <ButtonReset
                  onClick={btnReset}
                  className="btn_div"
                  type="reset"
                >
                  Reset
                </ButtonReset>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Govtkyc;

const Heading = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
align-items: center;
margin-top: 5rem;
margin-bottom: 3rem;
margin-right: 6rem;
>h1 {
  font-size: 2.5rem;
  text-align: center;
  padding-bottom: 2rem;
}
>button {
  width: 7rem;
  height: 3rem;
  font-size: 1.5rem;
  background-color: #2ec4b6;
  color: #fff;
  border: none;
  border-radius: 1rem;
}
  @media screen and (min-width: 769px) and (max-width: 991px) {
    font-size: 2rem;
    text-align: center;
    margin-left: 5rem;
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    font-size: 2rem;
    margin-left: 2rem;
  }
  @media screen and (min-width: 375px) and (max-width: 768px) {
    font-size: 1.5rem;
    margin-left: -11rem;
  }
  @media screen and (width: 768px) {
    margin-left: -5rem;
  }
`;
const FormControl = styled(Form.Control)`
  background: #f1f2f3;
  border-radius: 10px;
`;
const ButtonSubmit = styled(Button)`
  background: #2ec4b6;
  border: none;
  border-radius: 10px;
  :hover {
    background: #2ec4b6;
  }
`;
const ButtonReset = styled(Button)`
  background:  #2ec4b6;
  border: none;
  border-radius: 10px;
  color: #fff;
  :hover {
    background: #2ec4b6;
    color: #fff;
  }
`;

const Out = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 4rem;
  margin-left: 43rem;

  > button {
    width: 10rem;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    font-size: 1.5rem;
    background-color: red;
    color: #fff;
    text-align: center;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 2rem;
    margin-left: -2rem;
  }
`;

